import { Switch } from "@mui/material"
import { SwitchProps } from "@mui/material/Switch/Switch"
import React from "react"

export type CommonSwitchProps = SwitchProps

export const CommonSwitch: React.FunctionComponent<CommonSwitchProps> =
  props => {
    return (
      <Switch
        sx={{
          width: 80,
          height: 40,
          padding: 0,
          "& .MuiSwitch-switchBase": {
            padding: 0,
            margin: "5px",
            transitionDuration: "300ms",
            color: "action.normal",
            "&.Mui-checked": {
              transform: "translateX(40px)",
              color: "primary.contrastText",
              "& + .MuiSwitch-track": {
                backgroundColor: "primary.main",
                opacity: 1,
                border: 0,
              },
              "&.Mui-disabled + .MuiSwitch-track": {
                opacity: 0.5,
              },
            },
            "&.Mui-focusVisible .MuiSwitch-thumb": {
              color: "primary.main",
              border: "6px solid #fff",
            },
            "&.Mui-disabled .MuiSwitch-thumb": {
              color: "palette.grey[100]",
            },
            "&.Mui-disabled + .MuiSwitch-track": {
              opacity: 0.7,
            },
          },
          "& .MuiSwitch-thumb": {
            boxSizing: "border-box",
            width: 30,
            height: 30,
          },
          "& .MuiSwitch-track": {
            borderRadius: 40 / 2,
            backgroundColor: "#323740",
            opacity: 1,
          },
        }}
        {...props}
      />
    )
  }
