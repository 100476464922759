import { NewCollectionBlockchainEnum } from "../api"
import { Option } from "../type/Option"

export const mainNetOptions: Array<Option<string>> = [
  { label: "Ethereum", value: NewCollectionBlockchainEnum.Ethereum },
  { label: "Polygon", value: NewCollectionBlockchainEnum.Polygon },
  { label: "Diver Time Chain", value: NewCollectionBlockchainEnum.Diver },
]

export const testNetOptions: Array<Option<string>> = [
  {
    label: "Ropsten (Ethereum Testnet)",
    value: NewCollectionBlockchainEnum.Ethereum,
  },
  {
    label: "Mumbai (Polygon Testnet)",
    value: NewCollectionBlockchainEnum.Polygon,
  },
  {
    label: "Diver Time Chain (Testnet)",
    value: NewCollectionBlockchainEnum.Diver,
  },
]
