import { FormHelperText } from "@mui/material"
import { styled } from "@mui/material/styles"
import React, { InputHTMLAttributes } from "react"
import { Control, Controller } from "react-hook-form"
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto"

export type ImageUploadProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  "value"
> & {
  control: Control<any, any>
  name: string
  value?: File
  multiple?: boolean
  accept?: string
  preImage?: string
  errors: { [x: string]: any }
  borderRadius?: number
  width?: number
  height?: number
}

const UploadImageWrapper = styled("label")(({ theme }) => ({
  width: 100,
  height: 100,
  display: "inline-block",
  flexWrap: "wrap",
  border: "1px solid",
  borderColor: "#999999",
  borderRadius: 100,
  aspectRatio: "1 / 1",
  boxShadow: "0 1px 0 rgba(23, 45, 55, 0.05)",
  cursor: "pointer",
  color: theme.palette.grey["400"],
  backgroundColor: theme.palette.background.formControlBg,
  overflow: "hidden",
  "& .upload-input": {
    display: "none",
  },
  "&:hover": {
    borderWidth: 2,
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
  },
}))

const UploadImageDisplay = styled("div")(() => ({
  width: "100%",
  height: "100%",
  display: "flex",
  boxShadow: "0 1px 0 rgba(23, 45, 55, 0.05)",
  marginRight: "16px",
  marginBottom: "16px",
  overflow: "hidden",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
  "& .upload_image_preview": {
    width: "100%",
    height: "100%",
    display: "inline-block",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
}))

const UploadTextField = styled("div")(() => ({
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: "1.6rem",
}))

export const ImageUpload: React.FunctionComponent<ImageUploadProps> = ({
  width,
  height,
  borderRadius,
  ...props
}) => {
  return (
    <Controller
      control={props.control}
      name={props.name}
      render={({ field: { onChange, value } }) => (
        <>
          <UploadImageWrapper
            sx={{ borderRadius: borderRadius, width: width, height: height }}
            className={props.disabled ? "disabled" : ""}
          >
            {value || props.preImage ? (
              <UploadImageDisplay>
                <span
                  className="upload_image_preview"
                  style={{
                    backgroundImage: value
                      ? `url(${URL.createObjectURL(value)})`
                      : `url(${props.preImage})`,
                  }}
                />
              </UploadImageDisplay>
            ) : (
              <UploadTextField className="upload-input-wrapper">
                <InsertPhotoIcon
                  sx={{ color: "#999999", fontSize: "2.5rem" }}
                />
              </UploadTextField>
            )}
            <input
              accept={props.accept}
              onChange={event =>
                onChange
                  ? onChange({
                      target: {
                        value: event.target.files
                          ? event.target.files[0]
                          : undefined,
                      },
                    } as unknown as React.ChangeEvent<HTMLInputElement>)
                  : null
              }
              type="file"
              className="upload-input"
              disabled={props.disabled}
            />
          </UploadImageWrapper>
          {props.errors[props.name] && (
            <FormHelperText error>
              {props.errors[props.name]?.message}
            </FormHelperText>
          )}
        </>
      )}
    />
  )
}
