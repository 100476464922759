import { KeyboardArrowDown } from "@mui/icons-material"
import React from "react"
import { styled } from "@mui/material/styles"
import { FormControl, FormHelperText, Select } from "@mui/material"
import { SelectProps } from "@mui/material/Select/Select"
import { Control, Controller } from "react-hook-form"
import { useTranslation } from "react-i18next"

const CustomSelection = styled((props: SelectProps) => (
  <Select {...props} fullWidth />
))(({ theme }) => ({
  fontFamily: "inherit",
  minHeight: 60,
  backgroundColor: theme.palette.background.formControlBg,
  borderRadius: 10,
  borderColor: theme.palette.action.normal,
  fieldset: {
    borderColor: theme.palette.action.normal,
    borderWidth: 2,
  },
  select: {
    fontSize: 16,
    lineHeight: 1.15,
    padding: "15px 20px",
    textAlign: "left",
    WebkitTextFillColor: theme.palette.text.primary,
    "&:focus": {
      outline: 0,
    },
  },
  "&.MuiOutlinedInput-root:not(.Mui-error)": {
    "&:hover fieldset": {
      borderColor: theme.palette.primary.main,
    },
  },
  "&+.MuiFormHelperText-root.Mui-error": {
    fontSize: "14px",
  },
  ".MuiSvgIcon-root": {
    color: theme.palette.primary.main,
    fontSize: "32px",
    marginRight: "12px",
  },
}))
export const CommonSelection: React.FunctionComponent<
  SelectProps & {
    control: Control<any, any>
    name: string
    errors: { [x: string]: any }
    placeholder?: string
    hideDefaultOption?: boolean
  }
> = ({ children, hideDefaultOption, placeholder, ...props }) => {
  const { t } = useTranslation()

  const renderEndAdornment = () => {
    return <KeyboardArrowDown />
  }

  return (
    <Controller
      control={props.control}
      name={props.name}
      render={({ field: { onChange, onBlur, value } }) => (
        <FormControl fullWidth>
          <CustomSelection
            value={value}
            onBlur={onBlur}
            onChange={onChange}
            error={!!props.errors[props.name]}
            IconComponent={renderEndAdornment}
            {...props}
          >
            {!hideDefaultOption && (
              <option value={""} disabled>
                {placeholder || t("str_please_select")}
              </option>
            )}
            {children}
          </CustomSelection>
          {props.errors[props.name] && (
            <FormHelperText error>
              <span>{props.errors[props.name]?.message}</span>
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  )
}
