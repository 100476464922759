import React from "react"
import { useAsync } from "react-use"
import WalletContainer from "../../core/store/WalletContainer"
import { EmptyPermission } from "../common/EmptyPermission"
import { LoadingWrapper } from "../common/LoadingWrapper"
import { MainLayout } from "../common/MainLayout"
import { CreateContainer } from "./CreateContainer"

export type CreateProps = {}

export const Create: React.FunctionComponent<CreateProps> = () => {
  const { walletAddress } = WalletContainer.useContainer()
  const checkInstalledMetamask = useAsync(async () => {
    return {
      installed:
        typeof window !== "undefined" && window.ethereum && walletAddress,
    }
  }, [walletAddress])

  return (
    <MainLayout>
      <LoadingWrapper
        state={checkInstalledMetamask}
        keepLoading={data => !data}
        render={check =>
          check.installed ? <CreateContainer /> : <EmptyPermission />
        }
      />
    </MainLayout>
  )
}
