import * as React from "react"
import { useTranslation } from "react-i18next"
import { Create } from "../components/create/Create"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { getCustomConfig } from "../core/utils/getCustomConfig"

const CreateNFTPage: React.FunctionComponent = () => {
  const { t } = useTranslation()
  return (
    <Layout>
      <Seo
        title={`${t("str_create_nft")} | ${getCustomConfig().title_suffix}`}
        favicon={getCustomConfig().favicon}
      />
      <Create />
    </Layout>
  )
}

export default CreateNFTPage
