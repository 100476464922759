import { ethers } from "ethers"
import { navigate } from "gatsby"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useAsync } from "react-use"
import { AppContext } from "../../AppContext"
import abi from "../../asset/abi.json"
import {
  Collection,
  InlineObject5ActionEnum,
  InlineObject5BlockchainEnum,
  InlineObject8BlockchainEnum,
  UploadfileMIMEMIMEEnum,
} from "../../core/api"
import { DialogTypeEnum } from "../../core/const/DialogTypeEnum"
import AlertContainer from "../../core/store/AlertContainer"
import WalletContainer from "../../core/store/WalletContainer"
import { CreateNFTRequest } from "../../core/type/Request"
import { RPCError } from "../../core/type/RPCResponse"
import { connectProvider } from "../../core/utils/connectProvider"
import { AxiosError, generateErrorMsg } from "../../core/utils/generateErrorMsg"
import { generateRPCErrorMsg } from "../../core/utils/generateRPCErrorMsg"
import {
  getContractAddress,
  getCustomConfig,
} from "../../core/utils/getCustomConfig"
import { getIpfs } from "../../core/utils/getS3PreSignUrl"
import { uploadS3 } from "../../core/utils/uploadS3"
import { validationNetwork } from "../../core/utils/validationNetwork"
import { EmptyData } from "../common/EmptyData"
import { LoadingWrapper } from "../common/LoadingWrapper"
import { PrimaryButton } from "../common/PrimaryButton"
import { CreateDetail } from "./CreateDetail"

export type CreateContainerProps = {}

export const CreateContainer: React.FunctionComponent<CreateContainerProps> =
  () => {
    const { t } = useTranslation()
    const { pushAlert, openModal, closeModal } = AlertContainer.useContainer()
    const { walletAddress, chainId } = WalletContainer.useContainer()
    const [loading, setLoading] = useState("")
    const [collectionOption, setCollectionOption] = useState<Array<Collection>>(
      []
    )

    const getCollections = useAsync(async () => {
      const res = await AppContext.apiExecutor.collectionOwnedPost({
        walletAddress: walletAddress,
      })
      setCollectionOption(res.data.data || [])
      return res.data.data
    }, [])

    const mintNFT = async (
      metaDataUrl: string,
      form: CreateNFTRequest
    ): Promise<any> => {
      try {
        console.info("mintNFT", metaDataUrl)
        const res = await AppContext.apiExecutor.profileContractPost({
          walletAddress: walletAddress,
          blockchain: form.blockchain as InlineObject8BlockchainEnum,
        })
        const existContractAddress = res.data.contractAddr
        console.info("existContractAddress: ", existContractAddress)

        const provider = await connectProvider()

        const signer = provider.getSigner()
        const signature = await signer.signMessage(
          getCustomConfig().mintSignMessage
        )
        console.info("signature", signature)

        const etherPrice = ethers.utils.parseEther(
          (form.isSelling ? form.price : 0).toString()
        )
        console.info("etherPrice", etherPrice)

        const etherTaxPrice = ethers.utils.parseEther(
          getCustomConfig().taxFee.toString()
        )
        console.info("etherTaxPrice", etherTaxPrice)

        const supply = (form.supply || 1).toString()
        console.info("supply", supply)

        const contract = new ethers.Contract(
          getContractAddress(form.blockchain as InlineObject5BlockchainEnum),
          abi,
          signer
        )

        const tx = existContractAddress
          ? await contract.addNewItemforsell(
              existContractAddress,
              etherPrice,
              metaDataUrl,
              supply,
              form.isSelling,
              {
                value: etherTaxPrice,
              }
            )
          : await contract.createFirstItemforsell(
              etherPrice,
              metaDataUrl,
              supply,
              form.isSelling,
              {
                value: etherTaxPrice,
              }
            )
        console.info("tx", tx)
        return tx
      } catch (err) {
        console.info("err: ", err)
        setLoading("")
        pushAlert({
          message: generateRPCErrorMsg(err as RPCError),
          severity: "error",
        })
      }
    }

    const createNFT = async (form: CreateNFTRequest) => {
      setLoading("Creating NFT...")
      try {
        console.info("form", form)
        if (
          validationNetwork(
            chainId,
            form.blockchain as InlineObject5BlockchainEnum
          )
        ) {
          const res = await AppContext.apiExecutor.nftMintImageuploadPost({
            MIME: form.image.type as UploadfileMIMEMIMEEnum,
          })
          setLoading("Uploading Image...")
          uploadS3(res.data.s3PreSignedUrl || "", form.image)
            .then(async () => {
              setLoading("Uploading Metadata...")
              getIpfs(res.data.fileName || "")
                .then(async ipfRes => {
                  console.info("ipfRes", ipfRes)
                  const metaDataRes =
                    await AppContext.apiExecutor.nftMintMetadataPost({
                      ...form,
                      collection: JSON.stringify(
                        collectionOption.find(
                          collect => collect.collectionID === +form.collectionID
                        )
                      ),
                      collectionName:
                        collectionOption?.find(
                          collect => collect.collectionID === +form.collectionID
                        )?.name || "",
                      image: ipfRes.data.ipfs || "",
                      collectionID: +form.collectionID,
                    })
                  console.info("metaDataRes", metaDataRes)
                  setLoading("Minting NFT...")
                  mintNFT(metaDataRes.data.ipfs || "", form).then(tx => {
                    console.info("tx", tx)
                    if (tx) {
                      AppContext.apiExecutor
                        .nftTransactionhashPost({
                          walletAddress: walletAddress,
                          collectionID: +form.collectionID,
                          blockchain:
                            form.blockchain as InlineObject5BlockchainEnum,
                          transactionHash: tx.hash,
                          action: InlineObject5ActionEnum.Mint,
                        })
                        .catch()
                      setLoading("")
                      openModal({
                        title: t("str_mint_success"),
                        content: t("str_mint_success_message"),
                        type: DialogTypeEnum.SUCCESS,
                        close: closeModal,
                        buttonRight: {
                          text: t("str_goto_account"),
                          action: () => navigate("/account"),
                        },
                      })
                    }
                  })
                })
                .catch(() => {
                  setLoading("")
                  pushAlert({
                    message: "Uploaded Metadata Failed",
                    severity: "error",
                  })
                })
            })
            .catch(() => {
              setLoading("")
              pushAlert({
                message: "Uploaded Image Fail",
                severity: "error",
              })
            })
        } else {
          setLoading("")
          openModal({
            title: t("str_network_error_title"),
            content: t("str_network_error_message", {
              chainId: chainId,
              blockchain: form.blockchain,
            }),
            type: DialogTypeEnum.WARNING,
            close: closeModal,
            buttonRight: { text: t("str_network_error_confirmBtn") },
          })
        }
      } catch (err) {
        setLoading("")
        pushAlert({
          message: generateErrorMsg(err as AxiosError),
          severity: "error",
        })
      }
    }

    return (
      <LoadingWrapper
        state={getCollections}
        keepLoading={data => data === undefined}
        render={collections => (
          <>
            {collections && collections.length > 0 ? (
              <CreateDetail
                createNFT={createNFT}
                loading={loading}
                collections={collections || []}
              />
            ) : (
              <EmptyData
                img={"/images/empty_collection.png"}
                message={"No collection! Create your first Collection"}
              >
                <PrimaryButton
                  sx={{
                    height: 60,
                    width: "100%",
                    fontSize: 21,
                    marginTop: 5,
                  }}
                  color="primary"
                  variant="contained"
                  onClick={() => navigate("/create_collection")}
                >
                  {t("str_create_collection")}
                </PrimaryButton>
              </EmptyData>
            )}
          </>
        )}
      />
    )
  }
