import axios, { AxiosResponse } from "axios"
import { InlineResponse2002, PresignURL, UploadfileMIMEMIMEEnum } from "../api"

export async function getS3PreSignUrl(
  MIME: UploadfileMIMEMIMEEnum
): Promise<AxiosResponse<PresignURL>> {
  const apiUrl = ""
  return axios.post(apiUrl, { MIME }).then().catch()
}

export async function getIpfs(
  fileName: string
): Promise<AxiosResponse<InlineResponse2002>> {
  const apiUrl =
    "https://q3kjqft5rcphgh7itdfmtipb240yfjzw.lambda-url.ap-northeast-1.on.aws/"
  return axios.post(apiUrl, { fileName }).then().catch()
}
