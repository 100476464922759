import { yupResolver } from "@hookform/resolvers/yup"
import { Box, useTheme } from "@mui/material"
import React, { useEffect } from "react"
import { Controller, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Collection, NewCollectionBlockchainEnum } from "../../core/api"
import { ACCEPT_FILE_TYPE } from "../../core/const/acceptFileType"
import { NFTFormSchema } from "../../core/const/formSchema"
import { initialNFTForm } from "../../core/const/initialFormData"
import { mainNetOptions, testNetOptions } from "../../core/const/Options"
import { CreateNFTRequest } from "../../core/type/Request"
import { getCustomConfig } from "../../core/utils/getCustomConfig"
import { CommonSelection } from "../common/form/CommonSelection"
import { CommonSwitch } from "../common/form/CommonSwitch"
import { CommonTextField } from "../common/form/CommonTextField"
import { ImageUpload } from "../common/form/ImageUpload"
import { PrimaryButton } from "../common/PrimaryButton"

export type CreateDetailProps = {
  createNFT: (form: CreateNFTRequest) => void
  loading: string
  collections: Array<Collection>
}

export const CreateDetail: React.FunctionComponent<CreateDetailProps> = ({
  createNFT,
  loading,
  collections,
}) => {
  const { t } = useTranslation()
  const { palette } = useTheme()

  const isSellable = getCustomConfig().isSellable
  const isAllowSetSupply = getCustomConfig().isAllowSetSupply

  const {
    watch,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<CreateNFTRequest>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: yupResolver(NFTFormSchema),
    defaultValues: {
      ...initialNFTForm,
      isSelling: isSellable,
      supply: 1,
    },
  })

  useEffect(() => {
    setValue("collectionID", "")
  }, [watch("blockchain")])

  const renderFormControl = (
    title: string,
    hint: string,
    required: boolean,
    children: JSX.Element
  ) => {
    return (
      <div className="c-form__bl">
        <div className={`c-form__bl--label ${required ? "form-required" : ""}`}>
          {title}
          {hint && (
            <Box
              sx={{ color: palette.text.secondary }}
              className="c-form__bl--note mx-auto mb-1"
            >
              {hint}
            </Box>
          )}
        </div>
        <div className="c-form__bl--input mx-auto mb-4">{children}</div>
      </div>
    )
  }

  return (
    <div className="c-container mx-auto w-full px-5 pt-14 pb-28">
      <div className="c-form__head">
        <div>{t("str_create_nft")}</div>
        <p className="c-form__head--note mb-0">{t("str_required")}</p>
      </div>

      {renderFormControl(
        t("str_nft_photo"),
        t("str_nft_photo_hint"),
        true,
        <ImageUpload
          width={175}
          height={175}
          accept={ACCEPT_FILE_TYPE.toString()}
          errors={errors}
          control={control}
          name="image"
        />
      )}

      {renderFormControl(
        t("str_nft_name"),
        t(""),
        true,
        <CommonTextField
          type="text"
          placeholder={t("str_nft_name_placeholder")}
          variant="outlined"
          name="name"
          control={control}
          errors={errors}
          fullWidth
        />
      )}

      {renderFormControl(
        t("str_external_link"),
        t(""),
        false,
        <CommonTextField
          type="text"
          placeholder={t(`https://yoursite.io/goods/123`)}
          variant="outlined"
          name="external_url"
          control={control}
          errors={errors}
          fullWidth
        />
      )}

      {renderFormControl(
        t("str_nft_description"),
        t(""),
        true,
        <CommonTextField
          type="text"
          placeholder={t("str_nft_description_placeholder")}
          variant="outlined"
          name="description"
          control={control}
          errors={errors}
          multiline
          rows={7}
          fullWidth
        />
      )}

      {renderFormControl(
        t("str_blockchain"),
        t(""),
        true,
        <CommonSelection
          name="blockchain"
          control={control}
          errors={errors}
          variant="outlined"
          placeholder={t("str_blockchain_placeholder")}
          native={true}
          fullWidth
        >
          {(process.env.GATSBY_CHAIN_TYPE === "test"
            ? testNetOptions
            : mainNetOptions
          )
            .filter(item =>
              getCustomConfig().allowCrypto.includes(
                item.value as NewCollectionBlockchainEnum
              )
            )
            .map((item, index) => {
              return (
                <option value={item.value} key={index}>
                  {t(item.label)}
                </option>
              )
            })}
        </CommonSelection>
      )}

      {renderFormControl(
        t("str_nft_collection"),
        t(""),
        true,
        <CommonSelection
          name="collectionID"
          control={control}
          errors={errors}
          variant="outlined"
          placeholder={t("str_nft_collection_placeholder")}
          native={true}
          fullWidth
        >
          {collections
            .filter(item => item.blockchain === watch("blockchain"))
            .map((item, index) => {
              return (
                <option value={item.collectionID} key={index}>
                  {item.name}
                </option>
              )
            })}
        </CommonSelection>
      )}

      {isAllowSetSupply &&
        renderFormControl(
          t("str_nft_supply"),
          t(""),
          true,
          <CommonTextField
            type="text"
            placeholder={t("str_nft_supply_placeholder")}
            variant="outlined"
            name="supply"
            control={control}
            errors={errors}
            fullWidth
          />
        )}

      {isSellable &&
        renderFormControl(
          t("str_nft_selling_enable"),
          t(""),
          true,
          <Controller
            control={control}
            name="isSelling"
            render={({ field: { onChange, value } }) => (
              <CommonSwitch checked={value || false} onChange={onChange} />
            )}
          />
        )}

      {watch("isSelling") &&
        renderFormControl(
          t("str_nft_price"),
          t(""),
          true,
          <CommonTextField
            type="text"
            placeholder={t("str_nft_price_placeholder")}
            variant="outlined"
            name="price"
            control={control}
            errors={errors}
            disabled={!watch("isSelling")}
            fullWidth
          />
        )}
      <div className="mb-10" />
      <div className="c-form__submit">
        <PrimaryButton
          sx={{
            height: 70,
            width: "100%",
            maxWidth: 378,
            fontSize: 21,
            fontWeight: "normal",
          }}
          variant="contained"
          onClick={handleSubmit(createNFT)}
          disabled={!!loading}
        >
          {loading || t("str_mint_nft_btn")}
        </PrimaryButton>
      </div>
    </div>
  )
}
